import axios from "axios";
import { notify } from "./notify";
import { persistor } from "../store";
import { reactBackendUrl } from "../utils";

const backendURL = `${reactBackendUrl}/api/v1/`;

////////////////////////AUTH - Action Creator //////////////////////////

export const login = (data) => {
  return {
    type: "LOGIN",
    payload: data,
  };
};

export const logout = () => {
  return {
    type: "LOGOUT",
    payload: null,
  };
};

export const userUpdate = (data) => {
  return {
    type: "UPDATE_USER",
    payload: data,
  };
};

export const setForgotEmailAndToken = (data) => {
  return {
    type: "SET_FORGOT_EMAIL_AND_TOKEN",
    payload: data,
  };
};

//////////////////////// AUth - Actions //////////////////////////

export const loggingIn = async (data) => {
  try {
    const response = await axios.post(`${backendURL}user/login`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      notify(response.data.message, "success");
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      notify(
        Array.isArray(err.response?.data?.message)
          ? err.response?.data?.message[0]
          : err.response?.data?.message
      );
    } else {
      notify(err.message);
    }
  }
};

//////////////////////// -------- Custom Actions --------- //////////////////////////

export const customAction = async (
  url,
  mode,
  data,
  token,
  title,
  multipart = false,
  showNotify = true
) => {
  switch (mode) {
    case "post":
      try {
        const response = await axios.post(`${backendURL}${url}`, data, {
          headers: {
            "Content-Type": multipart
              ? "multipart/form-data"
              : "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response) {
          if (response.data) {
            showNotify && notify(response.data.message, "success");
          } else {
            showNotify && notify(response.data.message);
          }
          return response.data;
        }
      } catch (err) {
        if (err.response) {
          showNotify &&
            notify(
              Array.isArray(err.response?.data?.message)
                ? err.response?.data?.message[0]
                : err.response?.data?.message
            );
        } else {
          showNotify && notify(err.message);
        }
        return null;
      }
      break;
    case "put":
      try {
        const response = await axios.put(`${backendURL}${url}`, data, {
          headers: {
            "Content-Type": multipart
              ? "multipart/form-data"
              : "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          if (response.data) {
            showNotify && notify(response.data.message, "success");
          } else {
            showNotify && notify(response.data.message);
          }
          return response.data;
        }
      } catch (err) {
        if (err.response) {
          notify(
            Array.isArray(err.response?.data?.message)
              ? err.response?.data?.message[0]
              : err.response?.data?.message
          );
        } else {
          notify(err.message);
        }
      }
      break;
    case "patch":
      try {
        const response = await axios.patch(`${backendURL}${url}`, data, {
          headers: {
            "Content-Type": multipart
              ? "multipart/form-data"
              : "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200) {
          if (response.data) {
            title === "Profile" &&
              notify("Profile updated successfully", "success");
            showNotify && notify(response.data.message, "success");
          } else {
            showNotify && notify(response.data.message);
          }
          return response.data;
        }
      } catch (err) {
        if (err.response) {
          showNotify &&
            notify(
              Array.isArray(err.response?.data?.message)
                ? err.response?.data?.message[0]
                : err.response?.data?.message
            );
        } else {
          showNotify && notify(err.message);
        }
      }
      break;
    case "delete":
      try {
        const response = await axios.delete(`${backendURL}${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 204) {
          showNotify && notify(`${title} deleted successfully`, "success");
          return response;
        } else {
          showNotify && notify(response.data.message);
          return response;
        }
      } catch (err) {
        if (err.response) {
          showNotify &&
            notify(
              Array.isArray(err.response?.data?.message)
                ? err.response?.data?.message[0]
                : err.response?.data?.message
            );
        } else {
          showNotify && notify(err.message);
        }
      }
      break;
    case "fetch":
      try {
        const response = await axios.get(`${backendURL}${url}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data) {
          return response.data;
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          await persistor.purge();
          window.location.href = "/";
        }
      }
      break;
    default:
      break;
  }
};
