import { toast } from "react-toastify";
import { notifyError, notifySuccess, notifyWarning } from "../assets/index";

export const notify = (msg, type) => {
  let icon;
  let formattedMessage;
  switch (type) {
    case "success":
      icon = (
        <img
          src={notifySuccess}
          style={{ height: "35px", width: "30px" }}
          alt="Success"
        />
      );
      formattedMessage = (
        <div className="ms-2">
          <div className="fw-700 fs-18">Submission Successful</div>
          <div className="fs-15 fw-400">{msg}</div>
        </div>
      );
      toast.success(formattedMessage, { icon: icon });
      break;
    case "error":
      icon = (
        <img
          src={notifyError}
          style={{ height: "35px", width: "30px" }}
          alt="Error"
        />
      );
      formattedMessage = (
        <div className="ms-2">
          <div className="fw-700 fs-18">Error</div>
          <div className="fs-15 fw-400">{msg}</div>
        </div>
      );
      toast.error(formattedMessage, { icon: icon });
      break;
    case "warning":
      icon = (
        <img
          src={notifyWarning}
          style={{ height: "35px", width: "30px" }}
          alt="Warning"
        />
      );
      formattedMessage = (
        <div className="ms-2">
          <div className="fw-700 fs-18">Warning !</div>
          <div className="fs-15 fw-400">{msg}</div>
        </div>
      );
      toast.warning(formattedMessage, { icon: icon });
      break;
    default:
      icon = (
        <img
          src={notifyError}
          style={{ height: "35px", width: "30px" }}
          alt="Error"
        />
      );
      formattedMessage = (
        <div className="ms-2">
          <div className="fw-700 fs-18">Error</div>
          <div className="fs-15 fw-400">{msg}</div>
        </div>
      );
      toast.error(formattedMessage, { icon: icon });
  }
};

export const toastLoading = (message) => {
  const id = toast.loading(message);
  return id;
};

export const updateLoadingToast = (id, message, type) => {
  toast.update(id, {
    render: message,
    type: type || "success",
    isLoading: false,
    autoClose: 800,
  });
};
