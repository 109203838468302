const initialState = {
  user: {
    id: null,
    username: null,
    name: null,
  },
  token: null,
  refresh: null,
  isAuthenticated: false,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.refresh = action.payload.refresh;
      state.isAuthenticated = action.payload.token ? true : false;
      break;
    case "LOGOUT":
      state.user = initialState.user;
      state.token = null;
      state.isAuthenticated = false;
      break;
    case "SET_FORGOT_EMAIL_AND_TOKEN":
      state.user.forgotPasswordCode = action.payload.forgotPasswordCode;
      state.user.email = action.payload.forgotPasswordEmail;
      break;
    case "SET_REGISTER_VERIFY_EMAIL":
      state.user.email = action.payload.email;
      break;
    case "UPDATE_USER":
      state.user = action.payload;
      break;
    default:
  }
  return state;
};
