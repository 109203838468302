import React from "react";

const Footer = () => {
  return (
    <div
      className={`d-flex bg-white w-100 position-fixed bottom-0 justify-content-end align-items-center p-3 px-4`}
      style={{ border: "2px solid #F3F1F1" }}
    >
      <span>© 2024 Sofrik. All rights reserved</span>
    </div>
  );
};

export default Footer;
