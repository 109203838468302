import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{ minHeight: "80vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <Button
          onClick={() => navigate("/")}
          className="d-flex justify-content-center align-items-center border-radius-35 px-4 my-3"
        >
          <span className="fs-16 fw-500 text-white text-center">
            Go to Dashboard
          </span>
        </Button>
      </div>
    </>
  );
};
export default NotFound;
